const timeUtil = require('./TimeUtil');

module.exports = {
  GeneratePath: (isoCreatedDateStr, pageTitle, lanCode) => {
    const datePrefix = timeUtil.PadedISODate(isoCreatedDateStr);
    const title = pageTitle.split(' ').join('-');
    const path = `${datePrefix}/${lanCode}/${title}`.toLowerCase();
    return path;
  },
};
