import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const pathUtil = require('../util/PathUtil');
const timeUtil = require('../util/TimeUtil');

const GridImage = styled.div`
  grid-area: image;
`;
const GridTitle = styled.h5`
  grid-area: title;
  text-align: left;
  margin-bottom: 0px;
`;
const GridDesc = styled.p`
  textgrid-area: desc;
  text-align: left;
  @media (max-width: 768px) {
    display: none;
  }
`;
const GridMeta = styled.small`
  textgrid-area: meta;
  text-align: left;
  margin-bottom: 0px;
`;
const BlogListItem = ({ children }) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 200px;
        grid-template-areas:
          'image title'
          'image meta'
          'image desc';
        grid-gap: 10px;
      `}
    >
      {children}
    </div>
  );
};
const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={process.env.GATSBY_LANGUAGE === 'th' ? 'บล็อก' : 'Blogs'}
        lang={process.env.GATSBY_LANGUAGE}
      />
      <div className="container">
        <div className="page-container">
          <h3>Blog Posts</h3>

          {data.allMarkdownRemark.edges
            .filter(
              post =>
                Date.parse(post.node.frontmatter.created) < Date.now() &&
                Date.now() < Date.parse(post.node.frontmatter.expire)
            )
            .map((post, i) => {
              const path = pathUtil.GeneratePath(
                post.node.frontmatter.created,
                post.node.frontmatter.path_title,
                post.node.frontmatter.lan
              );
              return (
                <BlogListItem key={i}>
                  <GridImage>
                    <Link to={path}>
                      <img
                        alt="Failed To Load"
                        src={
                          post.node.frontmatter.feat_img.childImageSharp.fluid
                            .src
                        }
                      />
                    </Link>
                  </GridImage>
                  <GridMeta>
                    <i className="fas fa-user"></i>
                    {process.env.GATSBY_LANGUAGE === 'th'
                      ? 'โพสโดย '
                      : 'Posted by'}
                    {post.node.frontmatter.author}
                    {', '}
                    {process.env.GATSBY_LANGUAGE === 'th'
                      ? timeUtil.UTCThTimeStr(post.node.frontmatter.created)
                      : timeUtil.UTCEnTimeStr(post.node.frontmatter.created)}
                  </GridMeta>
                  <GridTitle>
                    <Link to={path}>{post.node.frontmatter.title}</Link>
                  </GridTitle>
                  <GridDesc>
                    <Link to={path}>{post.node.frontmatter.description}</Link>
                  </GridDesc>
                </BlogListItem>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($lan: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { lan: { eq: $lan }, include: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___created] }
    ) {
      edges {
        node {
          id
          frontmatter {
            path_title
            created
            expire
            include
            tags
            title
            title
            author
            lan
            description
            feat_img {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;
export default BlogPage;
