// date will be returned assuming UTC

module.exports = {
  PadedISODate: isoDateStr => {
    const dateObj = new Date(isoDateStr);
    //yyyy/mm/dd
    return (
      `${dateObj.getFullYear()}` +
      `/` +
      `${dateObj.getMonth() + 1}`.padStart(2, 0) +
      `/` +
      `${dateObj.getDate()}`.padStart(2, 0)
    );
  },
  UTCEnTimeStr: timestamp => {
    const dateObj = new Date(timestamp);
    // m/d/y
    return (
      `${dateObj.getUTCMonth() + 1}` +
      `/` +
      `${dateObj.getUTCDate()}` +
      `/` +
      `${dateObj.getUTCFullYear()}`
    );
  },
  UTCThTimeStr: timestamp => {
    const dateObj = new Date(timestamp);
    // d/m/y
    return (
      `${dateObj.getUTCDate()}` +
      `/` +
      `${dateObj.getUTCMonth() + 1}` +
      `/` +
      `${dateObj.getUTCFullYear()}`
    );
  },
};
